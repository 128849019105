import React from 'react';
import { Link } from 'gatsby';
import starIcon from '../../assets/images/star-icon.png';
import servicioFacturaElectronica from '../../assets/images/febos/servicio2.png';
import servicioAprobaciones from '../../assets/images/febos/servicio1.png';
import servicioEscritorioDigital from '../../assets/images/febos/servicio4.png';
import servicioGestionDeContratos from '../../assets/images/febos/servicio5.png';
import servicioPortalProveedores from '../../assets/images/febos/servicio3.png';
import servicioRendicionDeGastos from '../../assets/images/febos/servicio6.png';

const NuestrosPrincipalesServicios = () => {
  return (
    <section
      className="projects-area bg-color pt-100 pb-70"
      id={'servicios'}
    >
      <div className="container">
        <div className="section-title">
          <h2>Nuestros principales servicios</h2>
          <p>
            Todos nuestros servicios trabajan en conjunto como un equipo y están
            conectados entre sí, para una mayor experiencia.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box">
              <div className="image">
                <img
                  src={servicioAprobaciones}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Flujos de Aprobaciones
                </h3>
                <span>
                  Realiza acciones tales como aprobar, rechazar, firmar
                  electrónicamente documentos, entre otros.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box">
              <div className="image">
                <img
                  src={servicioFacturaElectronica}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Factura y Boleta Electrónica
                </h3>
                <span>
                  Gestiona los recursos y procesos de tus facturas y boletas
                  electrónicas. Utiliza tus puntos de ventas o ¡los de Febos!
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box">
              <div className="image">
                <img
                  src={servicioPortalProveedores}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Portal Proveedores
                </h3>
                <span>
                  Comunícate con tus proveedores, que puedan buscar información relevante para ellos como los compromisos de pago.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box">
              <div className="image">
                <img
                  src={servicioEscritorioDigital}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Escritorio Digital
                </h3>
                <span>
                  Crea, gestiona, edita y mucho más, tus documentos de manera
                  digital y de forma segura.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box ">
              <div className="image">
                <img
                  src={servicioGestionDeContratos}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Gestión de contratos
                </h3>
                <span>
                  Lleva el control de los contratos con seguimiento, trazabilidad,
                  estado y notificaciones según tus necesidades.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-projects-box">
              <div className="image">
                <img
                  src={servicioRendicionDeGastos}
                  alt="project"
                />
              </div>

              <div className="content">
                <h3>
                  Rendición de Gastos
                </h3>
                <span>
                  Echa un vistazo a la rendición de gastos y lleva un
                  seguimiento en tiempo real, de manera rápida y sencilla.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuestrosPrincipalesServicios;
