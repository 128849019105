import React from "react"
import sectorPrivado from "../../assets/images/febos/sector-privado.png"
import sectorPublico from "../../assets/images/febos/sector-publico.png"
import starIcon from "../../assets/images/star-icon.png"

const Sectores = () => {
  return (
    <React.Fragment>
      {/* Service Left Image Style */}
      <div className="about-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={sectorPrivado} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <h2>Sector privado</h2>
                  <p>
                    Gestiona todos los documentos de tu empresa, emite
                    documentos tributarios electrónicos desde la web o
                    integrando cualquier sistema contable. Administra
                    correctamente todos tus procesos, crea flujos de
                    aprobaciones, firma tus documentos de forma rápida y 100%
                    digital.
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Facturación y Boletas Electrónica
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Administración de Contratos
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Portal Proveedores
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Rendiciones de Gastos
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Gestión Documental
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Firma electrónica de Documentos
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}

      {/* Service Right Image Style */}
      <div className="our-mission-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <h2>Sector público</h2>
                  <p>
                    Digitaliza tus procesos, crea documentos, aprueba y firma en
                    un mismo lugar. Con Febos y Escritorio Digital podrás crear
                    flujos de trabajo y realizar todas estas actividades de
                    forma ágil y simple.
                  </p>

                  <ul className="our-mission-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Gestión Documental
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Rendiciones de Fondo Fijo
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Gestión y Aprobación de Pagos
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Oficina de Partes Electrónica
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Firma Electrónica de Documentos
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Gestión de Compras y Presupuestos
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img src={sectorPublico} alt="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Right Image Style */}
    </React.Fragment>
  )
}

export default Sectores
