import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import serviceIcon1 from "../../assets/images/febos/ic1.png"
import serviceIcon2 from "../../assets/images/febos/ic2.png"
import serviceIcon3 from "../../assets/images/febos/ic3.png"
import serviceIcon4 from "../../assets/images/febos/ic4.png"
import serviceIcon5 from "../../assets/images/febos/ic5.png"
import serviceIcon6 from "../../assets/images/febos/ic6.png"

const EstamosParaAyudarte = () => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Estamos para ayudarte</h2>
          <p>
            Te acompañamos durante todo el proceso de trabajo, en las buenas y
            en las malas, mediante nuestra buena atención al cliente y excelente
            calidad de servicio.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon1} alt="feature" width={120}/>
              </div>
              <h3>Chat veloz</h3>
              <p>
                Chatea online con nosotros y haznos saber tus inquietudes,
                nuestro equipo responderá en un abrir y cerrar de ojos.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon2} alt="feature" width={120}/>
              </div>
              <h3>Gestión S.O.S.</h3>
              <p>
                Si tu requerimiento nos toma algo más de tiempo, la plataforma
                de incidentes, nos ayudará a dar seguimiento.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon3} alt="feature" width={120}/>
              </div>
              <h3>Atención 12/5</h3>
              <p>
                Atendemos tus requerimientos desde temprano y hasta tarde, de
                lunes a viernes, entre las 08:00 de la mañana y las 20:00 de la
                tarde.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon4} alt="feature" width={120}/>
              </div>
              <h3>Uptime de 99,9%</h3>
              <p>
                Nuestros servicios se ejecutan de forma eficiente e inteligente
                gracias a la plataforma Amazon Web Services (AWS).
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon5} alt="feature" width={120}/>
              </div>
              <h3>A prueba de fallos</h3>
              <p>
                Nuestras soluciones están elaboradas con la mejor tecnología
                Serverless, sin servidores.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box">
              <div className="icon">
                <img src={serviceIcon6} alt="feature" width={120}/>
              </div>
              <h3>Soporte Exclusivo</h3>
              <p>
                Si necesitas comunicarte con todo nuestro equipo, te presentamos
                Febos VIC (Very Important Client), plataforma donde podemos
                chatear al instante.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EstamosParaAyudarte
