import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import PorQueElegirnos from "../components/Index/PorQueElegirnos"
import Sectores from "../components/Index/Sectores"
import EstamosParaAyudarte from "../components/Index/EstamosParaAyudarte"
import NuestrosPrincipalesServicios from "../components/Index/NuestrosPrincipalesServicios"
import Partners from "../components/Index/Partners"
import Clientes from "../components/Index/Clientes"
import EstasListoParaDarElSiguientePaso from "../components/Index/EstasListoParaDarElSiguientePaso"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <PorQueElegirnos />
      <Sectores />
      <EstamosParaAyudarte />
      <NuestrosPrincipalesServicios />
      <Clientes />
      <Partners />
      <EstasListoParaDarElSiguientePaso />
      <Footer />
    </Layout>
  )
}

export default Home
