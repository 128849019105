import React from 'react';
import ReactWOW from 'react-wow';
import cloudbook from '../../assets/images/partner/cloudbook.png';
import edigital from '../../assets/images/partner/e-digital.png';
import esign from '../../assets/images/partner/e-sign.png';
import entersoft from '../../assets/images/partner/entersoft.png';
import narvi from '../../assets/images/partner/narvi.png';
import ed from '../../assets/images/partner/escritoriodigital.png';
import seidor from '../../assets/images/partner/seidor.png';
import star from '../../assets/images/partner/starconsulting.png';
import thomas from '../../assets/images/partner/thomas.png';

const Partners = () => {
  let partners = [
    seidor, ed, edigital, narvi, star, entersoft, esign
  ];
  return (
    <div className="partner-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Juntos creamos valor</h2>
          <p>
            Porque queremos otorgarte un servicio de alta calidad, dimos en el
            clavo con una jugada estratégica, ¡concretamos alianzas con otras
            empresas!
          </p>
        </div>

        <div className="row justify-content-center align-items-center">
          {partners.map((empresa) => (
            <div className="col-lg-3 col-sm-4 col-md-3 col-6">
              <ReactWOW
                delay=".1s"
                animation="fadeInUp"
              >
                <div className="single-partner-item">
                  <img
                    src={empresa}
                    alt="partner"
                    width={'220px'}
                  />
                </div>
              </ReactWOW>
            </div>
          ))}
        </div>
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default Partners;
