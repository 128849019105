import React from "react"
import { Link } from "gatsby"
import projectStart from "../../assets/images/febos/next_step.png"
import shape from "../../assets/images/febos/footer_bg.png"

const EstasListoParaDarElSiguientePaso = () => {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={projectStart} alt="project" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>¿Estás listo para dar el siguiente paso?</h2>
              <p>
                ¡No esperes más! Tenemos la solución para todos tus problemas.
                Contáctanos y únete a la comunidad FEBOS.
              </p>

              <Link to="/contacto" className="default-btn">
                <i className="flaticon-web"></i>
                Comencemos
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape} alt="project" />
      </div>
    </div>
  )
}

export default EstasListoParaDarElSiguientePaso
