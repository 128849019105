import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"

const PorQueElegirnos = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <h2>¿Por qué elegirnos?</h2>
          <p>Razones sobran, pero estas son las más power.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-history"></i>
              </div>
              <h3>
                Tenemos Experiencia
              </h3>
              <p>
                No se nace siendo FEBOS, es por esto que, nos perfeccionamos día
                a día para ser los mejores y entregarte la mejor experiencia.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                Somos resolutivos
              </h3>

              <p>
                Transformamos tus problemas en eficientes plataformas digitales.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>

              <h3>
                Evolucionamos
              </h3>

              <p>
                El ser humano evoluciona y los conocimientos de FEBOS, también.
                Mejoramos nuestra metodología de trabajo y habilidades e
                implementamos nuevas herramientas para entregarte el mejor
                servicio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PorQueElegirnos
