import React from 'react';
import ReactWOW from 'react-wow';

import aduanas from '../../assets/images/clients/aduanas.png';
import arcos from '../../assets/images/clients/arcos.png';
import chilemat from '../../assets/images/clients/chilemat.png';
import cmpc from '../../assets/images/clients/cmpc.png';
import compass from '../../assets/images/clients/compass.png';
import corfo from '../../assets/images/clients/corfo.png';
import ejercitodechile from '../../assets/images/clients/ejercitodechile.png';
import engie from '../../assets/images/clients/engie.png';
import fch from '../../assets/images/clients/fch.png';
import goodyear from '../../assets/images/clients/goodyear.png';
import hoscar from '../../assets/images/clients/hoscar.png';
import maver from '../../assets/images/clients/maver.png';
import metrodesantiago from '../../assets/images/clients/metrodesantiago.png';
import minmujer from '../../assets/images/clients/minmujer.png';
import minrel from '../../assets/images/clients/minrel.png';
import mop from '../../assets/images/clients/mop.png';
import prochile from '../../assets/images/clients/prochile.png';
import schiappacasse from '../../assets/images/clients/schiappacasse.png';
import sercotec from '../../assets/images/clients/sercotec.png';
import sernac from '../../assets/images/clients/sernac.png';
import sna from '../../assets/images/clients/sna.png';
import sqm from '../../assets/images/clients/sqm.png';
import utalca from '../../assets/images/clients/utalca.png';
import vidacamara from '../../assets/images/clients/vidacamara.png';
import classNames from 'classnames';

const privados = [
  chilemat, engie, goodyear, schiappacasse, sqm, arcos, cmpc, compass, maver, vidacamara, sna
];
const publicos = [
  aduanas,
  corfo,
  ejercitodechile,
  fch,
  hoscar,
  minmujer,
  metrodesantiago,
  minrel,
  mop,
  sernac,
  sercotec,
  utalca,
  prochile
];
const Partners = () => {
  return (
    <div className="partner-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Ellos ya confían en nosotros</h2>
          <p>
            Algunos de los que ya viven la experiencia FEBOS, y tú, ¿qué
            esperas?
          </p>
        </div>
        <h3>Sector Público</h3>
        <div className="divider mt-10"></div>
        <div className="row justify-content-center align-items-center">
          {publicos.map((empresa, i) => (
            <div
              className={classNames('col-sm-4 col-6',
                {
                  'col-lg-2 col-md-2': i < 8,
                  'col-lg-3 col-md-3': i >= 8,
                }
              )}
            >
              <ReactWOW
                delay=".1s"
                animation="fadeInUp"
              >
                <div className="single-partner-item">
                  <img
                    src={empresa}
                    alt="partner"
                  />
                </div>
              </ReactWOW>
            </div>
          ))}
        </div>
        <div className="divider mt-10"></div>
        <h3>Sector Privado</h3>
        <div className="divider mt-10"></div>
        <div className="row justify-content-center align-items-center">
          {privados.map((empresa) => (
            <div className="col-lg-3 col-sm-4 col-md-3 col-6">
              <ReactWOW
                delay=".1s"
                animation="fadeInUp"
              >
                <div className="single-partner-item">
                  <img
                    src={empresa}
                    alt="partner"
                  />
                </div>
              </ReactWOW>
            </div>
          ))}
        </div>
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default Partners;
